import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTextField.figmaUrl.android} codeUrl={checklists.componentTextField.codeUrl.android} checklists={checklists.componentTextField.checklists} mdxType="ChecklistResourcesSection" />
    <p>{`Textfields allow user input. The border should light up simply and clearly indicating which field the user is currently editing.`}</p>
    <p>{`Legion has 5 basic types of textfield:`}</p>
    <ol>
      <li parentName="ol">{`Basic Text Field`}</li>
      <li parentName="ol">{`Text Field with Label`}</li>
      <li parentName="ol">{`Text Field with Helper`}</li>
      <li parentName="ol">{`Required Text Field`}</li>
      <li parentName="ol">{`Optional Text Field`}</li>
    </ol>
    <p>{`Legion has 5 states of textfield:`}</p>
    <ol>
      <li parentName="ol">{`State Normal`}</li>
      <li parentName="ol">{`Error State`}</li>
      <li parentName="ol">{`Success State`}</li>
      <li parentName="ol">{`Disable State`}</li>
      <li parentName="ol">{`Locked State`}</li>
    </ol>
    <p>{`You can also implement prefix and suffix for your project.`}</p>
    <p>{`For textfield customization, Legion provides 6 types of icon for the textfield:`}</p>
    <ol>
      <li parentName="ol">{`Left Icon`}</li>
      <li parentName="ol">{`Right Icon`}</li>
      <li parentName="ol">{`Prefix with Left Icon`}</li>
      <li parentName="ol">{`Prefix with Right Icon`}</li>
      <li parentName="ol">{`Suffix with Left Icon`}</li>
      <li parentName="ol">{`Suffix with Right Icon`}</li>
    </ol>
    <p>{`And also Legion has 5 variants :`}</p>
    <ol>
      <li parentName="ol">{`Password Field`}</li>
      <li parentName="ol">{`Date Field`}</li>
      <li parentName="ol">{`Time Field`}</li>
      <li parentName="ol">{`Dropdown Field`}</li>
      <li parentName="ol">{`Unit Field`}</li>
    </ol>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "width": 250,
            "height": 500,
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textfield/Textfield_android.gif",
            "alt": "Legion Textfield in Android XML"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <h3><strong parentName="h3">{`Basic text field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.edittext.LgnEditText
    // define your attribute's here
    ... />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnEditText(requireContext()).apply {
    // Your View's customization here
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Text Field with Label`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    android:hint="Email"
    ...
    />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    hint = "Email"
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Text Field with Helper`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:helperText="Masukkan email kamu!"
    ...
    />



`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    helper = "Masukkan email kamu"
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Required Text Field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:isRequired="true"
    ...
    />




`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    isRequired = true
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Optional Text Field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:isOptional="true"
    ...
    />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    isOptional = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`State Normal`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    //Your View's customization here
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Error State`}</strong></h3>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    error = "Email sudah digunakan!"
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Success State`}</strong></h3>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    succcess = "Email dapat digunakan!"
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Disable State`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    android:enabled="false"
    ...
    />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    isEnabled = true
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Locked State`}</strong></h3>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    isLocked = true
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Prefix Text`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:prefixText="Email"
    ...
    />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    prefixText = "email"
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Suffix Text`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:suffixText="\\@telkom.co.id"
    ...
    />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    suffixText = "email"
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Left Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:startIconDrawable="@drawable/ic_calendar"
    ...
    />



`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    startIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}
...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Right Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:endIconDrawable="@drawable/ic_calendar"
    ...
    />




`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    endIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Prefix with Left Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:prefixStartIconDrawable="@drawable/ic_calendar"
    ...
    />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    prefixStartIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Prefix with Right Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:prefixEndIconDrawable="@drawable/ic_calendar"
    ...
    />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    prefixEndIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Suffix with Left Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:suffixStartIconDrawable="@drawable/ic_calendar"
    ...
    />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    suffixStartIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Suffix with Right Icon`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleField
    ...
    app:suffixEndIconDrawable="@drawable/ic_calendar"
    ...
    />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnSingleField(requireContext()).apply {
    suffixEndIconDrawable = ContextCompat.getDrawable(requireContext(), R.drawable.ic_calendar)
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Password Field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnPasswordField
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnPasswordField(requireContext()).apply {
    // Your View's customization here
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Date Field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnCalendarField
    ...
    app:dateFormat="FULL_DATE"
    ...
    />

`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnCalendarField(requireContext()).apply {
    dateFormat = DateFormat.DEFAULT_DATE
}

...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Time Field`}</strong></h3>
    <h4>{`Static in Xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnTimeField
    // define your attribute's here
    ... />


`}</code></pre>
    <h4>{`Programatically`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
LgnTimeField(requireContext()).apply {
    // Your View's customization here
}


...
`}</code></pre>
    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`*`}{`) :`}</strong>{` Dynamic using Kotlin mean if you using Dynamic Layout or Declarative UI using kotlin`}</p>
    </InlineNotification>
    <br />
    <br />
    <h3><strong parentName="h3">{`Dropdown Field`}</strong></h3>
    <p>{`To use dropdown, first define widget or component in your xml.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnDropdownField
    // define your attribute's here
    ... />


`}</code></pre>
    <p>{`And then put dropdown items in your code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
etDropDown.addAll(items)
...
`}</code></pre>
    <br />
    <br />
    <h3><strong parentName="h3">{`Unit Field`}</strong></h3>
    <p>{`To use Unit Field, first define widget or component in your xml.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<com.telkom.legion.component.textfield.LgnSingleUnitField
    // define your attribute's here
    ... />



`}</code></pre>
    <p>{`And then put dropdown items in your code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "title=\"SampleFragment.kt\"",
        "title": "\"SampleFragment.kt\""
      }}>{`...
etUnit.addAll(items)

...
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Attribute Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Xml Attrs`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related method(s)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hint`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:hint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show hint on textfield`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Placeholder text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:placeholderText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`placeHolder`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show placeholder on textfield`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Helper text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helper`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show helper text directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Digits`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:digits`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To show digit number on textfield`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Input Type ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:inputType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`inputType`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set input type on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isEnable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set enable or disable text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Required Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isRequired`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set required status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional Status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isOptional`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set optional status on text area directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suffix Text ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:suffixText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`suffixText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set suffix text on text field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Prefix Text ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:prefixText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prefixText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set prefix text on text field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Override Dropdown Behaviour ⁴ ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:overrideDropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`overrideDropdown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To override default behaviour of dropdown`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Prefix End Icon Drawable ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:prefixEndIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prefixEndIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on behind text prefix on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Prefix Start Icon Drawable ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:prefixStartIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`prefixStartIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on front text prefix on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suffix End Icon Drawable ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:suffixEndIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`suffixEndIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on behind text suffix on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suffix Start Icon Drawable ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`app:suffixStartIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`suffixStartIconDrawable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set icon on front text suffix on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ellipsize Placement ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:ellipsize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ellipsize`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set where Ellipsize on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Max Lines ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:maxLines`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxLines`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set maximum lines on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Max Length ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`android:maxLength`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`maxLength`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set max length on text field directly via xml`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Locked Field ¹`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isLocked`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set locked field on `}<em parentName="td">{`LgnSingleField`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get Edit Text Instance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`editText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get edit text instance from component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Values ⁴ ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`addAll(List<String>)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set list of string to dropdown on component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Click Listener ⁴ ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnClickListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set click listener on dropdown if you want override default behaviour`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Dropdown Listener ⁴`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set listener on dropdown when value changed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Add Unit Listener ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`setOnUnitListener`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set listener on dropdown on unit field when value changed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get Selected Unit Value ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get selected unit on unit field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Get Edit Text on Dropdown Field Instance ⁵`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`dropDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To get edittext on dropdown field instance on unit field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Error State`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`isError`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set text field to error state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Error Message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`N/A`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To set message on error state on text field, if you set error message, text field will automaticly configure to error state`}</td>
        </tr>
      </tbody>
    </table>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p>{`¹) : Attribute only available on LgnSingleField Component`}</p>
      <br />
      <p>{`²) : Attribute only available on LgnCalendarField Component`}</p>
      <br />
      <br />
      <p>{`³) : Attribute only available on LgnTimeField Component`}</p>
      <br />
      <br />
      <p>{`⁴) : Attribute only available on LgnDropdownField Component`}</p>
      <br />
      <br />
      <p>{`⁵) : Attribute only available on LgnSingleUnitField Component`}</p>
    </InlineNotification>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      